import React from 'react';
import { withRouter } from "react-router-dom";
import Api from '../../api/utils.js'
import styles from './video.module.css'

import VideoPreview from './preview.js'

import './record.css'
import RecordRTC, { RecordRTCPromisesHandler } from 'recordrtc'
import { isSafari, isIOS, isMobile } from 'react-device-detect'

import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Countdown from 'react-countdown';

import ProgressBar from "@ramonak/react-progress-bar";

import axios from 'axios'

import SimpleReactValidator from 'simple-react-validator';
import Footer from '../footer.js'

const videoFormat = isSafari ? 'video/mp4' : 'video/webm'

class Video extends React.Component {
  constructor(props) {
    super(props)

    this.videoRef = React.createRef()
    this.ref = React.createRef()

    this.state = {
      video: {},
      videoUrl: null,
      upload: false,
      record: true,
      didPick: true,
      submitting: false,
      email: '',
      src: {},
      recorder: {},
      muted: true,
      hidden: false,
      file: null,
      showRetake: false,
      recording: false,
      videoSize: 0
    }

    this.validator = new SimpleReactValidator();

    this.onFileChange = this.onFileChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.pick = this.pick.bind(this)
    this.submitVideo = this.submitVideo.bind(this)
    this.onChange = this.onChange.bind(this)

    this.requestUserMedia = this.requestUserMedia.bind(this)
    this.captureUserMedia = this.captureUserMedia.bind(this)
    this.stopRecording = this.stopRecording.bind(this)
    this.startRecording = this.startRecording.bind(this)
    this.retake = this.retake.bind(this)
  }

  pick(e) {
    const target = e.target.dataset.id

    this.setState({
      [target]: true,
      didPick: true
    });
  }

  onFileChange(event) {
    console.log("Files: ", event.target.files)

    const file = event.target.files[0]

    this.setState({
      video: file,
      videoUrl: URL.createObjectURL(file),
      videoSize: file.size
    })
  }

  async componentDidMount() {}

  async requestUserMedia() {
    this.captureUserMedia((stream) => {
      window.gtag('event', 'video-grant-permissions');

      this.setState({
        recordingCountdown: true
      }, async () => {
        this.setState({
          src: stream,
          recorder: new RecordRTCPromisesHandler(stream, {
            type: ['video', 'audio'],
            mimeType: videoFormat
          }),
        }, async () => {
          this.ref.current.scrollIntoView()

          this.videoRef.current.srcObject = stream

          setTimeout(async () => {
            this.setState({
              recording: true,
              recordingCountdown: false,
            });

            await this.state.recorder.startRecording()
          }, 3000);

        })
      })

    });
  }

  async startRecording() {
    this.requestUserMedia()
  }

  async retake() {
    window.gtag('event', 'retake-video');

    this.setState({
      hidden: false,
      showRetake: false,
    })

    this.startRecording()
  }

  captureUserMedia(callback) {
    var params = { audio: true, video: {facingMode: 'user'} };

    navigator.mediaDevices.getUserMedia(params).then(stream => {
      callback(stream)
    }).catch(error => {
      window.gtag('event', 'video-failed-grant-permissions');

      console.error(error);

      if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
        alert('Video access denied. Please try again.')
      }
    })
  }

  async stopRecording() {
    try {
      await this.state.recorder.stopRecording()

      this.state.src.getAudioTracks().forEach(function(track) {
        track.stop();
     });

     this.state.src.getVideoTracks().forEach(function(track) {
        track.stop();
     });

      this.setState({
        recording: false,
      })

      window.gtag('event', 'stop-recording-video');

      const blob = await this.state.recorder.getBlob()

      console.log(blob)

      const file = new File([blob], `video/23892349938/${Date.now()}.${isSafari ? 'mp4' : 'webm'}`, {type: videoFormat})

      this.setState({
        hidden: true,
        showRetake: true,
        video: file,
        file: isSafari || isIOS ? window.webkitURL.createObjectURL(file) : URL.createObjectURL(file),
        videoSize: file.size
      })
    } catch (e) {
      console.error(e);

      this.setState({
        error: e.message
      });
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  async onSubmit(e) {
    e.preventDefault()

    window.gtag('event', 'submit-video');

    if (!this.validator.allValid()) {
      window.gtag('event', 'video-email-not-included');

      this.validator.showMessages()
      return this.forceUpdate();
    }

    this.setState({
      submitting: true
    })

    await this.submitVideo()
  }

  async submitVideo() {
    try {
      if (!this.state.video) {
        this.setState({
          error: "Video not included. Please try again.",
          submitting: false
        });
        return
      }

      const userId = localStorage.userId
      const video = this.state.video

      console.log(video)

      const locationState = this.props

      const { project } = locationState

      const projectId = project.id || 220

      const formData = new FormData()

      formData.append('file', video)
      formData.append('email', this.state.email)
      formData.append('project_id', projectId)

      console.log(formData)

      const onUploadProgress = event => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total)
        this.setState({
          percentCompleted: percentCompleted
        });
        console.log("Percent complete: ", percentCompleted);
      }

      const data = await axios.post(`${Api.baseUrl}/projects/${projectId}/videos/12sdkjiofew`, formData, {onUploadProgress})

      if (!data.error) {
        console.log("Submitted!")

        window.M.toast({html: "Your video was submitted!"})

        setTimeout(() => {
          window.M.Toast.dismissAll()
          if (!this.props.embed) {
            this.props.videoSubmitted()
          } else {

          }
        }, 3000);
      } else {
        window.gtag('event', 'video-submission-failed');

        console.log("Error!");

        this.setState({
          error: "There was an error submitting your video. Please try again."
        })
      }
    } catch (e) {
      window.gtag('event', 'video-submission-failed');

      console.error(e)

      this.setState({
        error: "There was an error submitting your video. Please try again.",
        submitting: false
      })
    }
  }

  render() {
    const project = this.props.project

    return (
      <div hidden={this.props.hidden}>
        <form onChange={this.onChange} onSubmit={this.onSubmit}>
          <div ref={this.ref}>
            { this.state.recording || this.state.recordingCountdown ? (
              <>
                <h4>Script</h4>
                <p><i>{project.script}</i></p>
              </>
            ) : null}
          </div>
          <div>
            { !this.state.didPick ? <button type='button' className={`btn btn-large ${styles.record}`} data-id='record' onClick={this.pick}>Start Recording</button> : null}
          </div>
          <div>
            { this.state.recordingCountdown ? <Countdown date={Date.now() + 3000} renderer={props => <h3>{props.seconds}</h3>}/> : null}
            { this.state.recording ? <h3 className='recording'>Recording</h3> : null}
            { this.state.recording || this.state.recordingCountdown ? <video ref={this.videoRef} autoPlay muted={this.state.muted} hidden={this.state.hidden} playsInline></video> : null }
            { <VideoPreview hidden={this.state.hidden} file={this.state.file} /> }
            <div>
              { !this.state.showRetake && !this.state.recording && !this.state.recordingCountdown ? <button type='button' className='btn btn-large' onClick={this.startRecording}>Start Recording</button> : null}
              { this.state.recording && !this.state.showRetake ? <button type='button' className='btn btn-large' onClick={this.stopRecording}>Stop Recording</button> : null}
              { this.state.showRetake ? <button type='button' className='btn btn-large' onClick={this.retake}>Retake</button> : null }
            </div>
          </div>
          {
            this.state.record && this.state.showRetake ? <input type='text' name='email' placeholder="Enter email"/> : null
          }
          <p>
            {
              this.state.record ? this.validator.message('email', this.state.email, 'required|email') : null
            }
          </p>
          {
            this.state.didPick && this.state.showRetake ? <p>By submitting, you agree to the <a target='_blank' href='https://app.termly.io/document/terms-of-use-for-website/61294424-632a-44d2-b006-0c4427e2396b'>Terms of Service</a> and <a target='_blank' href='https://app.termly.io/document/privacy-policy/cc4c35e5-8227-4417-ba5a-93588a916863'>Privacy Policy</a>.</p> : null
          }
          { this.state.didPick && this.state.showRetake ? <input type='submit' disabled={this.state.submitting} value='Submit' className='btn btn-large submit' required/> : null }
          { this.state.submitting && <p>Please do not close or leave window while video is uploading.</p> }
          {
            this.state.submitting ? <ProgressBar completed={this.state.percentCompleted} bgColor='#FFC52F' /> : null
          }
          { this.state.error ? <p className='error'>Error: {this.state.error}</p> : null}
        </form>
      </div>
    )
  }
}

export default withRouter(Video)
