import React from 'react';
import Footer from '../footer.js'
import Logo from '../logo.js'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";

import styles from './map.module.css'

import './map.css'

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import GeoJSON from 'geojson'

import { format, formatDistance, formatRelative, subDays } from 'date-fns'

import ProjectsAPI from '../../api/projectsApi.js'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

const colors = {
  'approved': '#008000',
  'videos_sent': '#FFC52F',
  'seeking_permit': '#00ace6',
  'delayed': '#b37400',
  'denied': '#ff0000',
  'videos_recorded': '#800080'
}

class Map extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      authorized: true,
      projects: [],
      selectedProject: null,
    }
  }

  async componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/promontoryindustries/ckery6yog4otv19meet074ms2', // stylesheet location
      center: [-122.431297, 37.773972], // starting position [lng, lat]
      zoom: 12, // starting zoom
    });

    let projects = await ProjectsAPI.all()

    this.setState({projects: projects})

    console.log(projects)

    for (const project of projects) {
      project.location = JSON.parse(project.location)
    }

    const collection = GeoJSON.parse(projects, {GeoJSON: 'location'})

    console.log('collection:', collection);

    var elems = document.querySelectorAll('.sidenav');
    var instances = window.M.Sidenav.init(elems, {});

    map.on('load', () => {
      map.addSource('projects', {
        type: 'geojson',
        data: collection
      })

      map.getSource('projects').once('data', () => {
        Object.keys(colors).forEach(status => {
          map.addLayer({
            id: status,
            type: 'circle',
            source: 'projects',
            paint: {
              'circle-radius': 7,
              'circle-color': colors[status]
            },
            filter: ['==', ['get', 'permit_status'], status]
          })

          map.on('mouseenter', status, function () {
            map.getCanvas().style.cursor = 'pointer';
          });

          map.on('mouseleave', status, function () {
            map.getCanvas().style.cursor = '';
          });

          map.on('click', status, e => {
            const project = projects.filter(p => p.id === e.features[0].properties.id)[0]

            console.log('project: ', project);

            this.setState({
              selectedProject: project
            })

          })
        })
      })


    })
  }

  render() {
    return (
      <div className={styles.container}>
        <ul id="slide-out" class="sidenav sidenav-fixed">
          <li><Logo/></li>
          <li>{this.state.selectedProject ? <Project project={this.state.selectedProject}/> : null}</li>
        </ul>
        <div className={styles.map} ref={el => this.mapContainer = el}></div>
      </div>
    )
  }
}

function Project(project) {
  console.log(project);

  const proj = project.project

  return (
    <div className={styles.project}>
      <h3 className={styles.projectTitle}>{proj.name}</h3>
      <img src={proj.image_urls ? proj.image_urls[0] : null}/>
      <h5>Links</h5>
      <a href={`https://streetlight.app/projects/${proj.slug}`}>Project page</a>
      <br/>
      <a href={`https://streetlight.app/videos/${proj.video_slug ? proj.video_slug : proj.id}/all`}>Videos page</a>
      <h5>Info</h5>
      <p>Developer: {proj.developer}</p>
      <p>Meeting date: {proj.meeting_date ? Date(proj.meeting_date) : 'n/a'}</p>
      <form>
        <select name='status' id='status'>
          <option value='approved'>Approved</option>
          <option value='seeking_permit'>Seeking Permit</option>
          <option value='videos_sent'>Videos Sent</option>
          <option value='delayed'>Delayed</option>
          <option value='denied'>Denied</option>
        </select>
      </form>
    </div>
  )
}

export default withRouter(Map)
