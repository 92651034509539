import React from 'react';
import ProjectsAPI from '../../api/projectsApi'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";

import ProjectMap from '../map/project.js'

import Search from '../search/search.js'

import styles from './dashboard.module.css'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      projects: [],
      approvalTime: 0,
      permittingTime: 0,
      meanTime: 0
    }

    this.onSearch = this.onSearch.bind(this)
  }

  async componentDidMount() {
    document.body.style.backgroundColor = "white"

    let projects = await ProjectsAPI.all()

    this.setState({projects: projects})

    const stats = await ProjectsAPI.stats()

    this.setState({
      approvalTime: stats.approvedDays,
      permittingTime: stats.approvalTime,
    });
  }

  onSearch(e) {

  }

  render() {
    console.log(this.state.projects)

    return (
      <div className={`container ${styles.white}`}>
        <h1 className='center'>Dashboard</h1>
        <p>Average time to approval after videos: {Math.round(this.state.approvalTime)} days</p>
        <p>Average time to permitting: {Math.round((this.state.permittingTime / 365))} years</p>

        <h3>Search</h3>
        <Search/>
      </div>
    )
  }
}

export default withRouter(Dashboard)
