import React from 'react';
import Video from './video.js'
import ProjectsAPI from '../../api/projectsApi.js'

import Loader from "react-loader-spinner";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";

class Embed extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      project: {},

    }
  }

  async componentDidMount() {
    try {
      const id = this.props.match.params.projectId
      console.log(id)
      if (!id) {
        return
      }

      const project = await ProjectsAPI.findOne(id)

      console.log(project);

      if (!project.id) {
        return this.setState({
          error: 404,
          loading: false
        });
      }

      this.setState({
        project: project,
        loading: false
      })
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div className='container'>
          <div className='row center'>
            <Loader type="ThreeDots" color="#FFC52F" height={80} width={80} />
          </div>
        </div>
      )
    }

    return (
      <div className='container black'>
        <div ref={this.videoRef} className='row'>
          <Video project={this.state.project} embed={true} videoSubmitted={this.videoSubmitted}/>
        </div>
      </div>
    )
  }
}

export default withRouter(Embed)
