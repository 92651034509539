import Api from './utils.js'

const ProjectsAPI = {}

ProjectsAPI.findOne = async function(id) {
  const project = await Api.baseCall(`/projects/${id}`, 'GET', {})
  return project
}

ProjectsAPI.addUpdate = async function(id, data) {
  const update = await Api.baseCall(`/projects/${id}/updates`, 'POST', data)
  return update
}

ProjectsAPI.getVideos = async function(id, password) {
  const authorized = await Api.baseCall(`/videos`, 'POST', {id: id, password: password})
  return authorized
}

ProjectsAPI.all = async function() {
  const projects = await Api.baseCall(`/projects/`, 'GET', {})
  return projects
}

ProjectsAPI.tracker = async function() {
  const projects = await Api.baseCall(`/projects/tracker`, 'GET', {})
  return projects
}

ProjectsAPI.changeStatus = async function(id, status) {
  const projects = await Api.baseCall(`/projects/${id}/status`, 'PUT', {status: status})
  return projects
}

ProjectsAPI.sendEmail = async function(id, password) {
  const projects = await Api.baseCall(`/projects/${id}/email`, 'POST', {password: password})
  return projects
}

ProjectsAPI.stats = async function(id, password) {
  const projects = await Api.baseCall(`/projects/stats`, 'GET', {password: password})
  return projects
}

ProjectsAPI.search = async function(query) {
  console.log(query)
  const projects = await Api.baseCall(`/projects/search?query=${query}`, 'GET', {})
  return projects
}


ProjectsAPI.lookupDistrict = async function(latitude, longitude) {
  const district = await Api.baseCall('/address/lookup', 'POST', {latitude: latitude, longitude: longitude})

  return district
}

export default ProjectsAPI
