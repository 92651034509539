import React from 'react';
import Footer from '../footer.js'
import Logo from '../logo.js'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";

import styles from './map.module.css'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import GeoJSON from 'geojson'

import ProjectMap from './project.js'

import { format, formatDistance, formatRelative, subDays } from 'date-fns'

import ProjectsAPI from '../../api/projectsApi.js'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

const colors = {
  'approved': '#008000',
  'videos_sent': '#FFC52F',
  'seeking_permit': '#00ace6',
  'delayed': '#b37400',
  'denied': '#ff0000',
  'videos_recorded': '#800080'
}

class TrackerMap extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      authorized: true,
      projects: [],
      selectedProject: null,
      showPassword: false
    }

    this.onSelect = this.onSelect.bind(this)
    this.sendEmail = this.sendEmail.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
  }

  async componentDidMount() {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/promontoryindustries/ckery6yog4otv19meet074ms2', // stylesheet location
      center: [-122.431297, 37.773972], // starting position [lng, lat]
      zoom: 12, // starting zoom
    });

    let projects = await ProjectsAPI.tracker()

    this.setState({projects: projects})

    console.log(projects)

    for (const project of projects) {
      project.location = JSON.parse(project.location)
    }

    const collection = GeoJSON.parse(projects, {GeoJSON: 'location'})

    console.log('collection:', collection);

    var elems = document.querySelectorAll('.sidenav');
    var instances = window.M.Sidenav.init(elems, {});

    const select = document.querySelectorAll('select');
    const selects = window.M.FormSelect.init(select, {});

    map.on('load', () => {
      map.addSource('projects', {
        type: 'geojson',
        data: collection
      })

      map.getSource('projects').once('data', () => {
        Object.keys(colors).forEach(status => {
          map.addLayer({
            id: status,
            type: 'circle',
            source: 'projects',
            paint: {
              'circle-radius': 7,
              'circle-color': colors[status]
            },
            filter: ['==', ['get', 'permit_status'], status]
          })

          map.on('mouseenter', status, function () {
            map.getCanvas().style.cursor = 'pointer';
          });

          map.on('mouseleave', status, function () {
            map.getCanvas().style.cursor = '';
          });

          map.on('click', status, e => {
            const project = projects.filter(p => p.id === e.features[0].properties.id)[0]

            console.log('project: ', project);

            this.setState({
              selectedProject: project
            })

          })
        })
      })


    })
  }

  sendEmail() {
    console.log(this.state);
    this.setState({
      showPassword: true
    });
  }

  onSelect() {

  }

  async changeStatus(e) {
    console.log("Changing status");
    try {
      await ProjectsAPI.changeStatus(this.state.selectedProject.id, e.target.value)

      this.setState({
        statusChanged: true
      });
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    if (!this.state.authorized) {
      return (
        <div className={styles.container}>
          <div className='row'>
            <form onChange={this.onChange} onSubmit={this.onSubmit}>
              <div className='input-field'>
                <input type='password' name='password' placeholder='Enter Password'/>
              </div>
              <input className='btn btn-large' type='submit' value="Submit"/>
            </form>
          </div>
        </div>
      )
    }

    return (
      <div className={styles.container}>
        <ul id="slide-out" class="sidenav sidenav-fixed">
          <li><Logo/></li>
          <li>{this.state.selectedProject ? <ProjectMap changeStatus={this.changeStatus} project={this.state.selectedProject} sendEmail={this.sendEmail}/> : null}</li>
        </ul>
        <div className={styles.map} ref={el => this.mapContainer = el}></div>
      </div>
    )
  }
}

export default withRouter(TrackerMap)
