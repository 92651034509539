const Api = {}

Api.baseUrl = process.env.REACT_APP_API_DOMAIN

Api.baseCall = async function(endpoint, method, data) {
  const options = {}

  options.method = method
  options.headers = {
    'Content-Type': 'application/json',
  }

  if (method !== 'GET') {
    options.body = JSON.stringify(data)
  }

  const response = await fetch(Api.baseUrl + endpoint, options)

  const json = await response.json()

  if (!response.ok) {
    return {status: response.status, error: json.error}
  }

  return json
}

export default Api
