import Api from './utils.js'

const CommentsAPI = {}

CommentsAPI.create = async function(projectId, params) {
  const comment = await Api.baseCall(`/projects/${projectId}/comments`, 'POST', params)
  return comment
}

CommentsAPI.hide = async function(comment) {
  const hideComment = await Api.baseCall(`/projects/${comment.project_id}/comments/${comment.id}/hide`, 'POST', {})
  return hideComment
}

export default CommentsAPI
