import React, {useState} from 'react'

import ProjectsAPI from '../../api/projectsApi'
import Results from './results.js'

const Search = ({onResults}) => {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])

  const onSearch = async (e) => {
    e.preventDefault()

    console.log(query)

    const projects = await ProjectsAPI.search(query)

    console.log(projects)

    setResults(projects)
  }

  return (
    <>
      <form onSubmit={onSearch}>
        <input className='input' type='text' onChange={e => setQuery(e.target.value)} placeholder='Search for project'/>
        <input type='submit' className='btn' value='Enter'/>
      </form>
      <Results results={results}/>
    </>
  )
}

export default Search
