import React from 'react';
import Footer from '../footer.js'
import Logo from '../logo.js'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";

import styles from './release.module.css'
import { Helmet } from "react-helmet";

class PressRelease extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className='container'>
        <header className='row'>
          <Logo/>
        </header>
        <h1 className={styles.title}>1,000+ Units of Housing Permitted In San Francisco in 2021 Through Video-Based Community Support</h1>
        <div className='center'>
          <img className={styles.img} src='https://promontory-industries.s3.us-west-2.amazonaws.com/Screen%20Shot%202021-03-18%20at%202.58.42%20PM.png' alt='468 Turk Street'/>
          <p><i>468 Turk Street, approved in May 2021</i></p>
        </div>
        <p><i>San Francisco, CA</i></p>
        <p>Streetlight, a company based in San Francisco, is excited to announce that it has streamlined the approval of 9 multifamily buildings, totaling over 1,000 units, in San Francisco in 2021.</p>
        <p>The approved housing included hundreds of affordable units.</p>
        <p>Streetlight accomplished this by sending community videos in support of housing to public officials, which led to permitting by the San Francisco Planning Commission.</p>
        <p>Proposed housing is often blocked by community opposition at public meetings, which leads to schedule delays or outright denials. Homebuilders have historically struggled to mobilize supporters to meetings.</p>
        <p>Streetlight solved this problem by sourcing and sending community videos, which gave homebuilders the necessary support to get their projects approved. Prominent homebuilders in San Francisco have confirmed the effectiveness of Streetlight’s video-based strategy.</p>
        <p>Videos are a more inclusive way of sourcing community input because they eliminate the requirement to attend and speak publicly at lengthy planning meetings.</p>
        <p>“The housing crisis has left many San Francisco residents unable to afford their rent,” said Isaac Rosenberg, Streetlight Co-Founder and CEO. “Streetlight is thrilled to make progress in streamlining the entitlement of much needed housing.”</p>
        <p>Streetlight works with homebuilders on a contract basis, and looks forward to ongoing success in creating a more equitable San Francisco.</p>
        <p><i>Email <a href='mailto:contact@streetlight.app'>contact@streetlight.app</a> if you’d like to work with Streetlight to get your project approved.</i></p>
      </div>
    )
  }
}

export default PressRelease
