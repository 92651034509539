import React from 'react';

import styles from './map.module.css'
import './map.css'
import ProjectsAPI from '../../api/projectsApi.js'

const statuses = [
  'approved',
  'seeking_permit',
  'videos_recorded',
  'videos_sent',
  'delayed',
  'denied',
]

class ProjectMap extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showPassword: false,
      password: '',
      displaySend: false,
      statusChanged: false
    }

    this.sendEmail = this.sendEmail.bind(this)
    this.changePassword = this.changePassword.bind(this)
  }

  changePassword(e) {
    this.setState({
      password: e.target.value
    })

  }

  componentDidMount() {
    const select = document.querySelectorAll('select');
    const selects = window.M.FormSelect.init(select, {});
  }

  async sendEmail() {
    console.log("works!");

    if (this.state.showPassword && this.state.password.length > 0) {
      const response = await ProjectsAPI.sendEmail(this.props.project.id, this.state.password)

      if (!response.match) {
        this.setState({
          error: `⛔️ ${response.error}`
        })
      } else {
        this.setState({
          error: '✅ Ready to send',
          displaySend: true,
        })
      }
    }

    this.setState({
      showPassword: true
    })
  }

  render() {
    const email = `mailto:${this.props.project.supervisor_email || ''}?subject=Videos in Support of ${this.props.project.name}&body=Hi Supervisor ${this.props.project.supervisor_name}, %0D%0A%0D%0APlease see videos in support of ${this.props.project.name}: https://streetlight.app/videos/${this.props.project.video_slug}/all %0D%0A%0D%0APassword: ${this.state.password} %0D%0A%0D%0AThank you, %0D%0A%0D%0AIsaac Rosenberg %0D%0A%0D%0AStreetlight`

    return (
      <div className={styles.project}>
        <h3 className={styles.projectTitle}>{this.props.project.name}</h3>
        <img src={this.props.project.image_urls ? this.props.project.image_urls[0] : null}/>
        <h5>Links</h5>
        <a target='_blank' href={`https://streetlight.app/projects/${this.props.project.slug}`}>Project page</a>
        <br/>
        <a target='_blank' href={`https://streetlight.app/videos/${this.props.project.video_slug ? this.props.project.video_slug : this.props.project.id}/all`}>Videos page</a>
        <h5>Info</h5>
        <p className={styles.p}>Developer: {this.props.project.developer}</p>
        <p className={styles.p}>Meeting date: {this.props.project.meeting_date ? Date(this.props.project.meeting_date) : 'n/a'}</p>
        <div>
          <button onClick={this.sendEmail} className='send-videos orange btn btn-large'><i className='material-icons right'>send</i>Send Videos</button>
        </div>
        {
          this.state.showPassword ?
          <form>
            <div className='input-field'>
              <input className={styles.input} type='password' autoComplete="new-password" onChange={this.changePassword}  name='password' placeholder='Enter Password'/>
            </div>
          </form>
          : null
        }
        {
          this.state.displaySend ?
          <>
            <a target='_blank' href={email}>Send email</a>
          </>
          : null
        }

        {
          this.state.error ? <p>{this.state.error}</p> : null
        }
        <form onChange={this.props.changeStatus}>
          <h5>Status</h5>
          <div className={`row input-field col s12 ${styles.select}`}>
            <select className={styles.select} name='status' id='status'>
              {
                statuses.map(status => {
                  return <option value={status} selected={status === this.props.project.permit_status}>{status}</option>
                })
              }
            </select>
          </div>
          {
            this.state.statusChanged ? <p>Status saved ✅</p> : null
          }
        </form>
      </div>
    )
  }
}

export default ProjectMap
