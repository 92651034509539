import React from 'react';

class FileForm extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className='files'>
        <div className='file-field input-field'>
          <div className='btn'>
            <span>Image</span>
            <input type='file' name='files' onChange={this.props.onFileChange}></input>
          </div>
          <div class="file-path-wrapper">
            <input class="file-path validate" type="text" placeholder="Click to Upload"/>
          </div>
        </div>
        {
          this.props.fileUrls.map(file => (
            <img src={file}/>
          ))
        }
      </div>
    )
  }
}

export default FileForm
