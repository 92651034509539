import React from "react"
import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"

import Video from '../videos/video.js'

import Footer from '../footer.js'

import ProjectsAPI from '../../api/projectsApi.js'

import Logo from '../logo.js'

import styling from './project.module.css'
import './project.css'
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel'

import * as turf from '@turf/turf'
import { Helmet } from "react-helmet";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";

import Loader from "react-loader-spinner";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

const url = 'https://calm-coast-22798.herokuapp.com'


class Project extends React.Component {
  constructor(props) {
    super(props)


    this.state = {
      project: {},
      categoryText: '',
      currentCategoryId: 0,
      isEditing: false,
      showRecord: false,
      loading: true,
      error: ''
    }

    this.videoRef = React.createRef()

    this.editPage = this.editPage.bind(this)
    this.recordVideo = this.recordVideo.bind(this)

    this.videoSubmitted = this.videoSubmitted.bind(this)
  }

  async componentDidMount() {

    try {
      const id = this.props.match.params.id
      if (!id) {
        return
      }

      const project = await ProjectsAPI.findOne(id)

      console.log(project);

      if (!project.id) {
        return this.setState({
          error: 404,
          loading: false
        });
      }

      this.setState({
        project: project,
        loading: false
      })

      if (project.slug && !isNaN(id)) {
        window.history.replaceState(null, '', `/projects/${project.slug}`)
      }
    } catch (e) {
      console.error(e)
    }
  }

  recordVideo(event) {
    event.preventDefault()

    window.gtag('event', 'record-video');

    this.setState({
      showRecord: true
    }, () => {
      this.videoRef.current.scrollIntoView()
    });
  }

  videoSubmitted() {
    window.location.reload()

    this.setState({
      showRecord: false
    })
  }

  editPage(e) {
    e.preventDefault()

    this.setState({
      isEditing: true
    });
  }

  render() {
    const { path, url } = this.props.match;

    if (this.state.loading) {
      return (
        <div className='container'>
          <header className='row'>
            <Logo/>
          </header>
          <div className='row center'>
            <Loader type="ThreeDots" color="#FFC52F" height={80} width={80} />
          </div>
        </div>
      )
    }

    if (this.state.error === 404) {
      return (
        <div className='container black'>
          <header className='row'>
            <Logo/>
          </header>
          <div className='row center'>
            <h1>This project does not exist.</h1>
          </div>
        </div>
      )
    }

    const councilmember = this.state.project.supervisor_name ? `${this.state.project.council_title || 'Supervisor'} ${this.state.project.supervisor_name}` : `the Councilmember`

    return (
      <div className="container black">
        <Helmet>
          <title>{this.state.project.name ?  `${this.state.project.name} | ` : ''}Streetlight</title>
          <meta property='description' content={this.state.project.description || ''} />
          <meta property='og:title' content={this.state.project.name || ''}/>
          <meta property='og:image' content={this.state.project.image_urls ? this.state.project.image_urls[0] : null}/>
          <meta property='og:description' content={this.state.project.description || ''} />
          <meta property='og:site_name' content='Streetlight' />
          <meta property='og:type' content='website'/>
          <meta name='twitter:card' content='summary_large_image'/>
          <meta name='twitter:site' content='@streetlight_app'/>
        </Helmet>
        <header className='row'>
          <Logo/>
        </header>
        <div className='row'>
          <h4 className={styling.title}>{this.state.project.name}</h4>
        <h4 className={styling.subtitle}>{this.state.project.id === 388 ? `Support 89 units of affordable housing, including workforce housing` : `Support ${this.state.project.units} new units at ${this.state.project.name}`}</h4>
        </div>
        <div className='row center image'><img src={this.state.project.image_urls ? this.state.project.image_urls[0] : null} alt={this.state.project.name}/></div>
        <div className='row'>
          <p className='center'>{this.state.project.description || ''}</p>
          <p className='center'>{this.state.project.body || ''}</p>
        </div>
        <div className='row'>
          <p className='center'></p>
        </div>
        <div className='row'>
          <h4 className='center'>{`Record a 15 second scripted video to send to ${councilmember}.`}</h4>
        </div>
        <div ref={this.videoRef} className='row'>
          <Video project={this.state.project} videoSubmitted={this.videoSubmitted}/>
        </div>
        <p>
          <a href='mailto:contact@streetlight.app'>Contact Us</a>
        </p>
        <Footer/>
      </div>

    )
  }
}

export default withRouter(Project)
