import React from 'react';
import ProjectsAPI from '../../api/projectsApi'
import CommentsAPI from '../../api/commentsApi'

import { withRouter } from "react-router-dom";

import styles from './video.module.css'

import ReactHlsPlayer from 'react-hls-player';

import Vimeo from '@u-wave/react-vimeo';

class AllVideos extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      project: {},
      authorized: this.props.match.params.projectId == '75fa3710-295f-40d0-8269-f4e4fcba421f',
      password: '',
      videos: [],
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.hideVideo = this.hideVideo.bind(this)
  }

  async hideVideo(e) {
    e.preventDefault()

    const commentId = e.target.dataset.id

    const comment = this.state.videos.filter(comment => comment.id == commentId)[0]

    const videos = this.state.videos.filter(comment => comment.id != commentId)

    this.setState({
      videos: videos
    });

    await CommentsAPI.hide(comment)


  }

  async componentDidMount() {
    // JLL Spark password-free page
    if (this.props.match.params.projectId == '75fa3710-295f-40d0-8269-f4e4fcba421f') {
      const videos = await ProjectsAPI.getVideos(this.props.match.params.projectId, process.env.REACT_APP_JLL_PWD)

      console.log(videos)

      console.log(videos.authorized);

      this.setState({
        authorized: true,
        project: videos.project,
        videos: videos.comments
      })
    }
  }

  async onSubmit(e) {
    e.preventDefault()

    if (!this.state.password) {
      return
    }

    const authorization = await ProjectsAPI.getVideos(this.props.match.params.projectId, this.state.password)

    if (authorization.authorized) {
      window.gtag('event', 'videos-authorized');

      this.setState({
        authorized: true,
        project: authorization.project,
        videos: authorization.comments
      })
    } else {
      window.gtag('event', 'videos-denied');
      window.M.toast({html: 'Unauthorized'})
    }
  }

  onChange(e) {
    this.setState({
      password: e.target.value
    });
  }

  render() {
    if (!this.state.authorized && this.props.match.params.projectId != 228) {
      return (
        <div className='container black'>
          <div className='row'>
            <form onChange={this.onChange} onSubmit={this.onSubmit}>
              <div className='input-field'>
                <input type='password' name='password' placeholder='Enter Password'/>
              </div>
              <input className='btn btn-large' type='submit' value="Submit"/>
            </form>
          </div>
        </div>
      )
    }

    return (
      <div className='container black videos'>
        <h1>{this.state.project.name}</h1>
        <div className='row videos'>
          <h3>{this.state.videos.length} Video Comments</h3>
          {
            this.state.videos ? this.state.videos.map(comment => {
              return (
                <div className='video'>
                  { comment.file_url.includes('stream.mux.com') ? <ReactHlsPlayer src={comment.file_url} autoPlay={false} controls={true} /> : null}
                  { comment.file_url.includes('embed.api.video') ? <iframe src={comment.file_url} frameborder='0' className={styles.video}></iframe> : null}
                  { comment.file_url.includes('amazonaws.com') ? <video controls playsInline src={comment.file_url}></video> : null}
                  { comment.file_url.includes('vimeo.com') ? <Vimeo className='video' video={comment.file_url} /> : null}
                  <button className='btn btn-large' data-id={comment.id} onClick={this.hideVideo}>Hide Video</button>
                </div>
              )
            }) : null
          }
        </div>
      </div>
    );
  }
}

export default withRouter(AllVideos)
