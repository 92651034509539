import React from 'react';
import ReactDOM from "react-dom";

import './upload.css'

import FileForm from '../fileform.js'
import Logo from '../logo.js'

import FieldsForm from './fieldsform.js'

import Geocode from "react-geocode";

import ProjectsAPI from '../../api/projectsApi'

import { withRouter } from "react-router-dom";

import Api from '../../api/utils.js'

Geocode.setApiKey(process.env.REACT_APP_GEOCODING_KEY)

class Upload extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      firstScreen: true,
      name: '',
      address: '',
      latitude: 0,
      longitude: 0,
      files: [],
      geojson: {},
      geojsonFile: {},
      fileUrls: [],
      units: 0,
      subtitle: '',
      body: '',
      description: '',
      supervisor_name: '',
      supervisor_email: '',
      permit_status: '',
      district: '',
      developer: '',
      affordable_units: 0,
      addressHelper: 'Include city & state in address',
      notes: '',
      url: '',
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.lookupAddress = this.lookupAddress.bind(this)
  }

  componentDidMount() {

  }

  onChange(event) {
    const target = event.target
    const value = target.value

    if (target.name === 'files' || target.name === 'geojson') {
      return
    }

    if (target.name === 'affordable_units') {

      return this.setState({
        affordable_units: target.checked ? 1 : 0
      })
    }

    this.setState({
      [target.name]: value
    })
  }

  async onSubmit(event) {
    event.preventDefault()

    try {
      let res
      if (this.state.address.length > 0) {
        res = await Geocode.fromAddress(this.state.address)

        console.log(res)

        const { lat, lng } = res.results[0].geometry.location;

        console.log(lat, lng);

        this.setState({
          latitude: lat,
          longitude: lng,
        })
      }

      const files = this.state.files
      const formData = new FormData()

      console.log(files);

      for (var i in files) {
        console.log(files[i]);
        formData.append('files', files[i])
      }

      let city

      if (this.state.address.length > 0) {
        formData.append('address', res.results[0].formatted_address)

        for (let i = 0; i < res.results[0].address_components.length; i++) {
          for (let j = 0; j < res.results[0].address_components[i].types.length; j++) {
            switch (res.results[0].address_components[i].types[j]) {
              case "locality":
                city = res.results[0].address_components[i].long_name;
                break;
            }
          }
        }

        formData.append('city', city)
      }

      formData.append('name', this.state.name)
      formData.append('script', this.state.script)
      formData.append('slug', this.state.slug)

      formData.append('neighborhood', this.state.neighborhood)

      formData.append('units', this.state.units)
      formData.append('affordable_units', this.state.affordable_units)
      formData.append('supervisor_name', this.state.supervisor_name)
      formData.append('supervisor_email', this.state.supervisor_email)
      formData.append('district', this.state.district)
      formData.append('developer', this.state.developer)
      formData.append('permit_status', this.state.permit_status)

      formData.append('notes', this.state.notes)
      formData.append('article_url', this.state.url)

      formData.append('latitude', this.state.latitude)
      formData.append('longitude', this.state.longitude)

      const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/upload`, {
        method: 'POST',
        body: formData
      })

      const json = await response.json()

      console.log("JSON: ", json)

      this.props.history.push(`/projects/${json.slug}`)
    } catch (e) {
      console.error(e)
    }

  }

  async lookupAddress() {
    let res

    if (this.state.address.length > 0) {
      try {
        res = await Geocode.fromAddress(this.state.address)

        console.log(res)

        const { lat, lng } = res.results[0].geometry.location;

        console.log(lat, lng);

        const address = res.results[0].formatted_address

        const district = await ProjectsAPI.lookupDistrict(lat, lng)

        console.log(district);

        const name = address.split(',')[0]

        this.setState({
          latitude: lat,
          longitude: lng,
          address: address,
          name: name,
          addressHelper: 'Address found ✅'
        })

        if (Object.keys(district).length > 0) {
          this.setState({
            supervisor_name: district.supervisor_name,
            supervisor_email: district.supervisor_email,
            district: district.district
          })
        }
      } catch (e) {
        console.error(e)

        this.setState({
          addressHelper: 'Address not found'
        });
      }

    }
  }

  onFileChange(event) {
    console.log("Files: ", event.target.files)

    this.setState({
      files: event.target.files,
    })

    console.log(this.state.files)

    let fileUrls = []
    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i]
      console.log("File: ", file)

      fileUrls.push(URL.createObjectURL(file))
    }

    console.log("urls: ", fileUrls)

    this.setState({
      fileUrls: fileUrls
    });
  }

  render() {
    return (
      <div className='container black project-upload'>
        <header className='row'>
          <Logo/>
        </header>
        <div>
          <h1 className='project-upload'>Project Upload</h1>
          <form onSubmit={this.onSubmit} onChange={this.onChange}>
            <FieldsForm
              address={this.state.address}
              lookupAddress={this.lookupAddress}
              addressHelper={this.state.addressHelper}
              supervisor_name={this.state.supervisor_name}
              supervisor_email={this.state.supervisor_email}
              district={this.state.district}
              name={this.state.name}
            />
            <FileForm
              firstScreen={this.state.firstScreen}
              onFileChange={this.onFileChange}
              fileUrls={this.state.fileUrls}
            />
            <div>
              <button className='btn btn-large'>Submit</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default withRouter(Upload)
