import React from 'react';
import CookieConsent from "react-cookie-consent";

class Footer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const date = new Date()
    const year = date.getFullYear()
    return (
      <footer>
        <p>© {year} Streetlight App, Inc.</p>
      </footer>
    );
  }
}

export default Footer
