import React, {useState} from 'react'

import ProjectMap from '../map/project.js'

const Results = ({results}) => {
  console.log(results)

  return (
    <div className='results'>
      {
        results.map(result => <ProjectMap project={result}/>)
      }
    </div>
  )
}

export default Results
