import React from 'react';

class FieldsForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showTitle: false
    }

    this.enterTitle = this.enterTitle.bind(this)
  }

  componentDidMount() {
    var elems = document.querySelectorAll('select');
    var instances = window.M.FormSelect.init(elems, {});


  }

  enterTitle() {
    this.setState({
      showTitle: true
    })
  }

  render() {
    return (
      <>
        <div className='row'>
          <h3 className='title'>Title: {this.props.name}</h3>
          <a href='#' onClick={this.enterTitle}>Custom Title</a>
          {
            this.state.showTitle ? <input type='text' name='name' placeholder='Enter Title'/> : null
          }
        </div>
        <div className='row'>
          <div className='input-field'>
            <input className='input' type='text' name='address' placeholder='Address' value={this.props.address}/>
            <span class='helper-text'>{this.props.addressHelper}</span>
          </div>
          <div className='input-field'>
            <button type='button' className='btn btn-large' onClick={this.props.lookupAddress}>Find Address</button>
          </div>
        </div>
        <div className='row'>
          <div className='input-field'>
            <input className='input' type='text' name='district' placeholder='District' value={this.props.district}/>
          </div>
        </div>
        <div className='row'>
          <div className='input-field'>
            <input className='input' type='text' name='supervisor_name' placeholder='Supervisor Name' value={this.props.supervisor_name}/>
          </div>
        </div>
        <div className='email row'>
          <div className='input-field'>
            <input className='input' type='text' className='email' name='supervisor_email' placeholder='Supervisor Email' value={this.props.supervisor_email}/>
          </div>
        </div>
        <div className='row'>
          <div className='input-field'>
            <input className='input' type='number' name='units' placeholder='Units'/>
          </div>
        </div>
        <div className='row'>
          <p>
            <label>
              <input className='input filled-in' type='checkbox' name='affordable_units'/>
              <span>Affordable units?</span>
            </label>
          </p>
        </div>
        <div className='row input-field col s12'>
          <select name='permit_status' id='status'>
            <option value='' disabled selected>Permit Status</option>
            <option value='approved'>Approved</option>
            <option value='seeking_permit'>Seeking Permit</option>
            <option value='delayed'>Delayed</option>
            <option value='denied'>Denied</option>
          </select>
        </div>
        <div className='row input-field col s12'>
          <input type='text' name='url' placeholder='Article URL'/>
        </div>
        <div className='row input-field col s12'>
          <textarea id="notes" name='notes' class="materialize-textarea"></textarea>
          <label for="notes">Notes</label>
        </div>
      </>
    )
  }
}

export default FieldsForm
