import React from 'react';
import { isSafari, isIOS, isMobile } from 'react-device-detect'

const videoFormat = isSafari || isIOS ? 'video/mp4' : 'video/webm'

class VideoPreview extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <video src={ !isSafari || !isIOS ? this.props.file : null} hidden={!this.props.hidden} playsInline controls preload='metadata'>
        { isSafari || isIOS ? <source type={videoFormat} src={`${this.props.file}#t=0.1`}/> : null }
      </video>
    )
  }
}

export default VideoPreview
