import Api from './utils.js'

const UsersAPI = {}

UsersAPI.create = async function(params) {
  const user = await Api.baseCall('/users/create', 'POST', params)
  return user
}

UsersAPI.findOne = async function(email) {
  const user = await Api.baseCall(`/users/findOne?email=${email}`, 'GET', {})
  return user
}

UsersAPI.findById = async function(id) {
  const user = await Api.baseCall(`/users/findOne?userId=${id}`, 'GET', {})
  return user
}

UsersAPI.joinWaitlist = async function(data) {
  const message = await Api.baseCall(`/waitlist`, 'POST', data)
  return message
}

UsersAPI.subscribe = async function(data) {
  const subscription = await Api.baseCall('/users/subscribe', 'POST', data)
  return subscription
}

UsersAPI.checkSubscription = async function(projectId, userId) {
  const subscription = await Api.baseCall(`/users/subscribed?projectId=${projectId}&userId=${userId}`, 'GET', {})
  return subscription
}

export default UsersAPI
