import React, { useRef } from 'react';
import styles from '../index.module.css';
import Logo from './logo.js'

import '../home.css'

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Vimeo from '@u-wave/react-vimeo';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  withRouter
} from "react-router-dom";

import UsersAPI from '../api/usersApi'

import Footer from './footer'

class Home extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      showWaitlist: false,
    }

    this.ref = React.createRef()

    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  async joinWaitlist(e) {
    e.preventDefault()

    console.log("show waitlist");

    this.setState({
      showWaitlist: true,
      email: '',
      first_name: '',
      last_name: '',
      zipcode: '',
    });
  }

  onChange(event) {
    const value = event.target.value
    const target = event.target

    this.setState({
      [target.name]: value
    })
  }

  async onSubmit(event) {
    event.preventDefault()

    const data = {
      ...this.state
    }

    delete data.showWaitlist

    const response = await UsersAPI.joinWaitlist(data)

    console.log(response.message)

    if (response.message !== "You've already requested a demo") {
      // Show message on screen
      this.ref.current.close()
    }

    window.M.toast({html: response.message})
  }

  render() {
    return (
      <div className={`container ${styles.container}`}>
        <header className='row'>
          <img className='col s10 l4 pull-m1' src='/streetlight.png' alt='Streetlight'></img>
        </header>
        <div className='row'>
          <h2 className={styles.description}>Source Videos for your Community Engagement process.</h2>
          <br/>
          <Vimeo className='video' video='https://vimeo.com/917169217/35f348b45a?share=copy'/>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default Home
