import React from 'react';

import Upload from './components/upload/upload.js'
import Project from './components/projects/project.js'
import Home from './components/home.js'

import PressRelease from './components/press/release.js'
import AllVideos from './components/videos/all.js'
import Embed from './components/videos/embed.js'

import Map from './components/map/map.js'
import TrackerMap from './components/map/tracker.js'
import Dashboard from './components/dashboard/dashboard.js'

import mapboxgl from 'mapbox-gl'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/projects/:id">
          <Project />
        </Route>
        <Route path='/videos/:projectId/all'>
          <AllVideos/>
        </Route>
        <Route path="/upload">
          <Upload/>
        </Route>
        <Route path='/maps/results'>
          <Map/>
        </Route>
        <Route path='/maps/tracker'>
          <TrackerMap/>
        </Route>
        <Route path='/press'>
          <PressRelease/>
        </Route>
        <Route path='/dashboard'>
          <Dashboard/>
        </Route>
        <Route path='/embed/:projectId'>
          <Embed/>
        </Route>
        <Route path="/" component={Home}/>
      </Switch>
    </Router>
  );
}

export default App;
