import React from 'react';

class Logo extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <img className='col s12 l4 pull-m1' src='/streetlight.png' alt='Streetlight'></img>
    );
  }
}

export default Logo
